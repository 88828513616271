import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

import "../styles/index.scss"

import TourTable from "../components/tourtable"

const Shows = () => {
  return (
    <Layout>
      <Head title="Shows" />
      <TourTable />
    </Layout>
  )
}

export default Shows
