import React from "react"
import * as blogStyles from "./blog.module.scss"
import "../styles/index.scss"
import * as tourStyles from "./tourtable.module.scss"
import Moment from "moment"
import { OutboundLink } from "gatsby-plugin-google-analytics"

// var testDate = Moment(Date()).format("MMM DD,YYYY")
// console.log(testDate)

class ShowTable extends React.Component {
  state = {
    loading: true,
    error: false,
    fetchedData: [],
  }

  componentDidMount() {
    fetch(
      "https://rest.bandsintown.com/artists/Unusual%20Demont/events/?app_id=a19c12afc51766a28563628a0628db54"
    )
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(json => {
        console.log(json)
        this.setState({
          fetchedData: json,
          loading: false,
        })
      })
  }
  render() {
    const { loading, fetchedData } = this.state

    return (
      <div className={`${blogStyles.blogDiv}`}>
        <div className={`${blogStyles.blogPost}`}>
          {fetchedData.map(show => (
            <div className={`${tourStyles.tourDiv}`}>
              <p style={{ fontSize: "14pt" }}>{show.title}</p>
              <p>
                {Moment(show.datetime).format("MMM DD, YYYY")} &nbsp; // &nbsp;{" "}
                {show.venue.location} &nbsp; // &nbsp;&nbsp;
                {/* if show.offers[0] is null or undefined then use the {show.url} */}
                {(() => {
                  if (show.offers[0] != null) {
                    return (
                      <OutboundLink
                        href={show.offers[0].url} // link to actual tickets
                        // href={show.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="unusualButtonSmall">Tickets</button>
                      </OutboundLink>
                    )
                  } else {
                    return (
                      <OutboundLink
                        // href={show.offers[0].url}
                        href={show.url} // link to bandsintown
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="unusualButtonSmall"> RSVP </button>
                      </OutboundLink>
                    )
                  }
                })()}
              </p>

              {/* <OutboundLink
                // href={show.offers[0].url}
                href={show.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Tickets
             </OutboundLink>*/}
              <hr style={{ width: "60px", border: "1px solid black" }} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default ShowTable
